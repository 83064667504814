<template>
	<!-- Modal -->
	<transition name="fade">
		<div
			v-if="modal.show"
			class="modal fade show"
			:id="modal.id"
			tabindex="-1"
			role="dialog"
			aria-labelledby="ModalLabel"
			style="display: block;"
		>
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="ModalLabel">
							<slot name="title"></slot>
						</h5>
						<button
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close"
							v-on:click.prevent="modalClose"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<slot></slot>
					</div>
					<div class="modal-footer">
						<slot name="footer"></slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: "Modal.vue",
	props: ["modal"],

	mounted() {
		//alert('mounted');
	},

	methods: {
		modalClose() {
			//this.modal.show = false;

			this.$set(this.modal, "show", false);
			this.$emit("closeModal");
		}
	}
};
</script>

<style lang="scss">
.modal {
	background: rgba(#6c757d, 0.7);

	z-index: 99997;

	transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
</style>
