<template>
	<div
		id="publisherEl"
		class="publisherEl"
		:class="{ publishAudio: publishAudio, publishVideo: publishVideo }"
	>
		<i class="material-icons">mic_off</i>
	</div>
</template>

<script>

export default {
	name: "Publisher.vue",
	props: ["session", "publishAudio", "publishVideo", "localTrack"],

	data() {
		return {
			//publisher: null,
			audioInputs: null,
			// tracks: null,
		};
	},

	methods: {
		async initPublisher() {

			this.localTrack.forEach(function (track) {
				if(track.kind !== 'data') {
					document.getElementById('publisherEl').append(track.attach());
				}
			})


			this.$emit("publisherConnected", this.publisher);
			console.log("emit publisherConnected");

			this.$emit("publisherCompleted");
			console.log("emit publisherCompleted");

			this.$emit("publisherCreated", this.publisher);

		}
	},
	watch: {
		publishAudio(newVal) {
			if (this.publisher) {
				try {
					this.publisher.publishAudio(!!newVal);
				} catch (err) {
					console.warn(err);
				}
			}
		},
		publishVideo(newVal) {
			console.log("publishVideo: " + newVal);

			if (this.publisher) {
				try {
					this.publisher.publishVideo(!!newVal);
				} catch (err) {
					console.warn(err);
				}
			}
		}
	},

	mounted: function() {
		this.initPublisher();
	} // mounted
};
</script>

<style lang="scss">
.publisherEl {
	width: 160px;
	height: 110px;
	position: absolute;
	right: 15px;
	top: 15px;
	border-radius: 5px;
	transition-duration: 0.9s;
	z-index: 999;

	.OT_publisher {
		border-radius: 5px;
		box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.3);
	}

	i {
		position: absolute;
		color: white;
		width: 40px;
		height: 40px;
		top: 50%;
		left: 50%;
		z-index: 1000;
		font-size: 40px;
		margin-left: -20px;
		margin-top: -15px;
		display: block;
	}

	video {
		opacity: 0.1;
		//outline: 3px solid red;
	}
}

.publisherEl.publishAudio {
	i {
		display: none;
	}
}

.videoViewModemini .publisherEl {
	width: 60px;
	height: 40px;
}

.OT_widget-container {
	background-color: #343a40;
}

.OT_video-poster {
	background-size: auto 75%;
}

.OT_fit-mode-cover .OT_video-poster {
	background-position: center center;
}

.publishVideo {
	video {
		opacity: 1;
	}
}
video{
  width: 100%;
  height: 100%;
}
</style>
