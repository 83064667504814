<template>
	<div id="app" :class="[{ show_pre: showPre }, 'environment-' + environment]">
		<app-nav :showPre="showPre">
			<label class="debug-only text-primary">
				<input class="mr-1" v-model="showPre" type="checkbox" name />show data
			</label>
		</app-nav>

		<app-browser-notifications></app-browser-notifications>

		<pending-video-conferences></pending-video-conferences>

		<main role="main" class="py-3" style="margin-top: 110px">
			<!--			<pre>user: {{ user }}</pre>-->
			<!--			<pre>user.rtnow_admin: {{user.rtnow_admin}}</pre>-->
			<!--			<pre>user.rtnow_rt: {{user.rtnow_rt}}</pre>-->
			<transition :name="transitionName" mode="out-in">
				<router-view class="child-view"></router-view>
			</transition>
		</main>

		<!-- Global Modal -->
		<transition name="fade">
			<div
				v-if="globalModuleParams.show"
				class="modal fade show global_modal"
				:id="globalModuleParams.id"
				tabindex="-1"
				role="dialog"
				aria-labelledby="ModalLabel"
				style="display: block;"
			>
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="ModalLabel">
								{{ globalModuleParams.title }}
							</h5>

							<button
								type="button"
								class="close"
								data-dismiss="modal"
								aria-label="Close"
								v-on:click.prevent="globalModuleParams.close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div v-html="globalModuleParams.body"></div>
						</div>
						<div class="modal-footer">
							<button
								v-for="(button, index) in globalModuleParams.buttons"
								type="button"
								class="btn btn-primary"
								v-on:click.prevent="button.method"
								data-dismiss="modal"
								:key="'gmb-button-' + index"
							>
								{{ button.text }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</transition>

		<transition name="fade">
			<a id="ajaxLoader" v-if="$store.state.ajaxLoader">
				<span></span>
			</a>
		</transition>

		<p v-if="version" class="text-secondary text-center mt-4">
			<small>version {{ version }}</small>
		</p>
	</div>
</template>

<script>
import Nav from "./components/Nav.vue";
import BrowserNotifications from "./components/BrowserNotifications.vue";

import PendingVideoConferences from "./components/conference/PendingVideoConferences.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import {SyncClient} from "twilio-sync";

export default {
	name: "App",

	data() {
		return {
			msg: "Welcome to Your Vue.js App",

			showPre: false,

			transitionName: "slide-left",

			chaChingAudio: null,

			bumBumAudio: null,

			ringRingAudio: null,

			ringRingInt: false
		};
	},

	computed: {
		...mapState(["version", "environment", "user"]),
		...mapGetters(["loggedIn", "globalModuleParams"]),

		api() {
			return process.env.API_URL;
		}
	},

	methods: {
		...mapActions(["ajax", "setDestination", "globalModule", "globalAlert", "connectToTwilioSync", "getPendingCalls"]),

		testGlobalModule() {},

		testGlobalAlert() {
			this.globalAlert({
				title: "Alert",
				body: "Hello to the alert"
			});
		},

		bumBum() {
			this.bumBumAudio = new Audio("/static/audio/Error.mp3");
			this.bumBumAudio.play();
		},

		chaChing() {
			this.chaChingAudio = new Audio("/static/audio/Notification.mp3");
			this.chaChingAudio.play();
		},

		ringRing(loop) {
			this.ringRingAudio = new Audio("/static/audio/Ring.mp3");

			if (loop) {
				this.ringRingInt = setTimeout(() => {
					this.ringRing(true);
				}, 5000);
			} else {
				this.ringRingAudio.pause();
				clearInterval(this.ringRingInt);
				return false;
			}

			this.ringRingAudio.play();
		}
	},
	components: {
		"app-browser-notifications": BrowserNotifications,
		"app-nav": Nav,
		//'tokbox-session': Session,
		"pending-video-conferences": PendingVideoConferences
		//Datepicker
	},

	watch: {
		$route(to, from) {
			// conditional transition animaiton
			const toDepth = to.path.split("/").length;
			const fromDepth = from.path.split("/").length;
			this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";

			// remember where the user was trying to go
			if (
				to.fullPath != "/password/forgot" &&
				to.fullPath != "/welcome" &&
				to.fullPath != "/login" &&
				to.fullPath != "/logout"
			) {
				this.setDestination(to.fullPath);
			}
		},
		// if user is logged out, it will remove the twilio sync document. back end is doing it but this code handles the scenario where user session is timed out
		loggedIn(newVal) {
			// if session times out and loggedIn becomes false, it deletes the document that the client was connected to
			if (newVal === false) {
				var token = localStorage.getItem('twilioSyncAccessToken');

				const syncClient = new SyncClient(token, { loglevel: 'debug' });

				syncClient.document(localStorage.getItem('documentUniqueName')).then(function(doc) {
					doc.removeDocument().then(function() {
						console.log('Document removed.');
					});
				});

				localStorage.removeItem('twilioSyncAccessToken');
				localStorage.removeItem('documentUniqueName');
			}
		}
	},
	created() {
		// when user reloads the whole page, this method will be called and it will connect to twilio sync document/websocket
		this.connectToTwilioSync();
	},

	mounted() {
		console.log("4:16 +++++++++++++++++++++++++++++++++++++++++++++++");

		this.getPendingCalls();




		//var tis = this;
		//var p = window.location.hash.includes("password/reset") ? false : true;
		// this.ajax({
		//   pushLogin: p,
		//   url: '/authentication/check',
		//   data: {},
		//   success: (json,context) => {
		//
		//
		//
		//   }
		//
		// });
	}
};
</script>

<style lang="scss">
@import "./assets/css/main.scss";

.global_modal {
	pre {
		display: block !important;
	}
}
</style>
