// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";

import VeeValidate from "vee-validate";
Vue.use(VeeValidate);

import VueTextareaAutosize from "vue-textarea-autosize";
Vue.use(VueTextareaAutosize);

import VueMoment from "vue-moment";
Vue.use(VueMoment);

// jQuery Library
window.jQuery = window.$ = require("jquery");

// Vuex
import store from "./store";

Vue.config.productionTip = true;

// full calendar
//import FullCalendar from "vue-full-calendar";
import "fullcalendar/dist/fullcalendar.min.css";

//Vue.use(FullCalendar);

// global components
import Modal from "./components/Modal.vue";
Vue.component("app-modal", Modal);

import router from "./router";

/* eslint-disable no-new */
// new Vue({
// 	el: '#app',
// 	router,
// 	store,
// 	components: { App },
// 	template: '<App/>'
// })

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
