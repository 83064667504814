<template>
	<span></span>
</template>

<script>
/*

  Browser notifications for Safari

  APNS
  Apple Push Notification Service - API

  We need to handle unsubscribing from notifications

*/

import { mapGetters, mapActions } from "vuex";

export default {
	name: "Nav",

	//props: ['showPre'],

	data() {
		return {
			//collapsed: true
		};
	},

	computed: {
		...mapGetters(["loggedIn"])
	},

	watch: {
		loggedIn() {
			if (this.loggedIn) {
				// Let's check if the browser supports notifications
				if (!("Notification" in window)) {
					console.error("This browser does not support desktop notification");
				}

				// Let's check whether notification permissions have already been granted
				else if (Notification.permission === "granted") {
					console.log(
						"Permission to receive notifications has already been granted"
					);

					//this.initBrowserNotifications();
				}

				// Otherwise, we need to ask the user for permission
				else if (Notification.permission !== "denied") {
					console.log("Notification.permission !== 'denied'");
					this.initBrowserNotifications();
				}
			} // if
		}
	},

	methods: {
		...mapActions([
			"ajax"
			//'getPendingCalls'
		]),

		initBrowserNotifications() {
			if (!("serviceWorker" in navigator)) {
				// Service Worker isn't supported on this browser, disable or hide UI.
				return;
			}

			if (!("PushManager" in window)) {
				console.warn(
					"Push notifications are not currently supported for this browser.  Please try Chrome, Firefox or Edge."
				);
				// Push isn't supported on this browser, disable or hide UI.
				return;
			}

			// otherwise
			console.log("browser notifications are supported");

			/*
			 * At this point, we know that the browser notification feature is supported.
			 * The next step is to register our Service Worker.
			 */

			if ("serviceWorker" in navigator) {
				this.requestPermission().then(
					() => {
						this.subscribeUserToPush();
					},
					() => {
						console.warn("user rejected permission");
					}
				);
			}
		}, // initBrowserNotifications()

		requestPermission() {
			return new Promise(function(resolve, reject) {
				const permissionResult = Notification.requestPermission(function(
					result
				) {
					// Handling deprecated version with callback.
					resolve(result);
				});

				if (permissionResult) {
					permissionResult.then(resolve, reject);
				}
			}).then(function(permissionResult) {
				if (permissionResult !== "granted") {
					throw new Error("Permission not granted.");
				}
			});
		},
		// from https://justmarkup.com/log/2017/02/implementing-push-notifications/
		urlB64ToUint8Array(base64String) {
			const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
			const base64 = (base64String + padding)
				// eslint-disable-next-line no-useless-escape
				.replace(/\-/g, "+")
				.replace(/_/g, "/");

			const rawData = window.atob(base64);
			const outputArray = new Uint8Array(rawData.length);

			for (let i = 0; i < rawData.length; ++i) {
				outputArray[i] = rawData.charCodeAt(i);
			}
			return outputArray;
		},

		subscribeUserToPush() {
			var tis = this;

			return navigator.serviceWorker.register("/sw3.js").then(
				registration => {
					//this
					console.log("1. ServiceWorker registration successful");

					// PRIVATE KEY see config.php
					//
					//var vapidPublicKey = new Uint8Array('BLJWMCrkanVHe3vAl53Po2pUiv3Dy3pEYKdp6YugksTTnC_uTX66GdoDJrzQ-SGjHVMbsvlll6SWQ0VWzUMOIYU');

					var subscribeOptions = {
						userVisibleOnly: true,
						applicationServerKey: this.urlB64ToUint8Array(
							"BLJWMCrkanVHe3vAl53Po2pUiv3Dy3pEYKdp6YugksTTnC_uTX66GdoDJrzQ-SGjHVMbsvlll6SWQ0VWzUMOIYU"
						)
					};

					console.log("subscribeOptions", subscribeOptions);

					return registration.pushManager.subscribe(subscribeOptions).then(
						pushSubscription => {
							console.log("2. pushSubscription!!!");
							console.log(pushSubscription);

							console.log(
								"Received PushSubscription: ",
								JSON.stringify(pushSubscription)
							);

							var stringified = JSON.stringify(pushSubscription);

							var subscriptionData = JSON.parse(stringified);

							// var subscriptionData = {
							//   endpoint: pushSubscription.endpoint,
							//   keys: {
							//     p256dh: pushSubscription.getKey('p256dh'),
							//     auth: pushSubscription.getKey('auth')
							//   }
							// };

							// The push subscription details needed by the application
							// server are now available, and can be sent to it using,
							// for example, an XMLHttpRequest.

							// fetch('/api/conference/subscribe',{
							//   method: "POST",
							//   headers: {
							//       'Content-Type': 'application/json'
							//   },
							//   body: JSON.stringify(pushSubscription)
							// })
							// .then(function(response) {
							//   return response;
							// })
							// .then(function(text) {
							//   console.log('User is subscribed.');
							//   //hasSubscription = true;
							//
							//   //updatePushButton();
							// })
							// .catch(function(error) {
							//   //hasSubscription = false;
							//   console.error('error fetching subscribe', error);
							// });

							/*

            $endpoint = $this->input->post('endpoint');
      			$key_auth = $this->input->post('key_auth');
      			$key_p256dh = $this->input->post('key_p256dh');

            */

							console.log("2.1 subscriptionData");
							console.log(subscriptionData);

							tis.ajax({
								url: "/conference/subscribe/",
								data: subscriptionData,
								success: () => {
									console.log("3. Subscription has been saved to server.");
								}
							});
						},
						error => {
							// During development it often helps to log errors to the
							// console. In a production environment it might make sense to
							// also report information about errors back to the
							// application server.
							console.warn("pushManager.subscribe error: ", error);
						}
					);
				},
				function(err) {
					// Registration failed
					console.warn("ServiceWorker registration failed: ", err);
				}
			);
			// .then(function(pushSubscription) {
			//   console.log('PushSubscription: ', JSON.stringify(pushSubscription));
			//   return pushSubscription;
			// });
		}
	},

	mounted() {
		//alert('browser notifications mounted');
	}
};
</script>
