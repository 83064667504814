<template>
		<div class="innerInnerSubscriberEl">
			<div>
				<img id="avatar-img" v-if="showAvatar" :src="avatar1" alt="video muted">
				<i :style="showMicDisabled" class="material-icons mic">mic_off</i>
			</div>

			<div :style="videoDisplay" :id="this.idToGet" class="innerInnerSubscriberEl">
				<i :style="showMicDisabled" class="material-icons mic">mic_off</i>
			</div>
		</div>

</template>

<script>

export default {
	name: "subscriber",
	props: ["stream", "session", "indexNumber",],
	data() {
		return {
			activity: null,
			logLevel: 0,
			subscriber: null,
			subscribeToAudio: true,
			idToGet: 'innerInnerSubscriberEl' + this.indexNumber,
			avatar1: require('../../assets/avatar.png'),
			showAvatar: false,
			showMicDisabled: 'display:none',
			videoDisplay: '',
		};
	},

	watch: {
		subscribeToAudio() {
			this.subscriber.subscribeToAudio(this.subscribeToAudio);
		}
	},
	mounted: function() {
		let idToAppend = 'innerInnerSubscriberEl' + this.indexNumber;

		this.$parent.$on('track-disabled', (track, index) => {
			if(index === this.indexNumber && track.kind === 'video') {
				this.showAvatar = true
				this.videoDisplay = 'display: none'
			} else if(index === this.indexNumber && track.kind === 'audio') {
				this.showMicDisabled = ''
			}
		});

		this.$parent.$on('track-enabled', (track,index) => {
			if(index === this.indexNumber && track.kind === 'video') {
				this.showAvatar = false;
				this.videoDisplay = ''
			} else if(index === this.indexNumber && track.kind === 'audio') {
				this.showMicDisabled = 'display:none'
			}
		});

		this.$parent.$on('track-unpublished', (index, track) => {
			console.log('event track unpublished fired', index, this.indexNumber)
			if(index === this.indexNumber && (track.kind === 'video' || track.kind === 'data')) {
				this.showAvatar = true
				this.videoDisplay = 'display: none'
			}
			if(index === this.indexNumber && track.kind === 'audio') {
				this.showMicDisabled = ''
			}
		});

		this.$parent.$on('track-published', (track) => {
			console.log('event track published fired', track)
			this.stream = this.stream.filter(item => item !== null);
			const hasVideo = this.stream.some(track => track.kind === 'video');
			if(!hasVideo && track.kind === 'video') {
				this.showAvatar = false
				// this.videoDisplay = ''
				document.getElementById(idToAppend).append(track.track.attach())
			}
			const hasAudio = this.stream.some(track => track.kind === 'audio');
			if(!hasAudio && track.kind === 'audio') {
				this.showMicDisabled = 'display:none'
				document.getElementById(idToAppend).append(track.track.attach())
			}
		});


		this.stream.forEach(function(item) {
			if(item) {
				if(item.kind !== 'data') {
					document.getElementById(idToAppend).append(item.attach())
					if(item.kind === 'video' && !item.isEnabled) {
						this.showAvatar = true
						this.videoDisplay = 'display: none'
					} else if(item.kind === 'audio' && !item.isEnabled) {
						this.showMicDisabled = ''
					}

				}
			}
		}.bind(this));


		this.$emit("subscriberCreated", this.subscriber);
	}
};
</script>

<style lang="scss">
.subscriberTalking {
  outline: 4px solid red;
}

.videoViewModemax .OT_subscriber_error > p {
  padding: 100px 200px;
}

.videoViewModemini .OT_subscriber_error > p {
  padding: 65px 10px;
}

.innerInnerSubscriberEl {
  position: relative;

  .OT_subscriber {
	z-index: 997;
  }
}


.subscriberControls {
  //width: 40px;
  //height: 40px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 998;
  display: block;
  opacity: 0.2;
  transition-duration: 0.3s;
  //outline: 3px solid red;
}

.vdr:hover {
  .subscriberControls {
	opacity: 1;
  }
}

.name {
}
.OT_subscriber .OT_bar {
  height: 57px;
}
.OT_subscriber .OT_name {
  left: 60px;
}

.OT_subscriber:hover .OT_name {
  margin-top: 10px;
}

.OT_root h1,
.OT_root h2,
.OT_root h3,
.OT_root h4,
.OT_root h5,
.OT_root h6 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";
}

i {
  position: absolute;
  color: white;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  z-index: 1000;
  font-size: 40px;
  margin-left: -20px;
  margin-top: -15px;
  display: block;

}

.mic{
  color: red;
}

#avatar-img {
	width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


</style>

