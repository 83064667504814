<template>
	<div
			class="mx-2 sticky-top"
			:class="{ 'pending-padding': pending && pending.length }"
			v-show="
			loggedIn &&
				((pending && pending.length) || activePendingId || activeRequestId)
		"
	>
		<div
				class="squareBubble"
				:class="{ showSquareBubble: $store.state.pendingAjaxing }"
		>
			<em class="spinner"></em>Checking for incoming calls.
		</div>

		<ul
				v-if="pending && pending.length"
				class="list-group list-group-flush"
				style="width: 100%; z-index: 999;"
		>
			<li
					v-if="statusActive.length"
					class="list-group-item list-group-item-danger mt-2"
			>
				<div class="justify-content-between row">
					<div class="col-sm">
						<a class="btn btn-outline-link">
							You have {{ statusActive.length }} active call(s). No new calls will
							be routed to you at this time.
						</a>
					</div>
					<div class="col-sm text-right">
						<a
								style="background-color: rgba(114, 28, 36, .85); border-color: rgb(114, 28, 36);"
								title="Mark this call as completed."
								href
								v-on:click.prevent="clearAllAcitveCalls()"
								class="btn btn-danger"
						>
							<i class="material-icons">check</i>Clear All Calls
						</a>
					</div>
				</div>
			</li>

			<li
					v-for="(call, i) in pending"
					:key="i + '-pending-video-conferences-pending'"
					class="list-group-item mt-2"
					v-show="call.show"
					:class="{
					'list-group-item-primary': call.status == 'active',
					'list-group-item-danger': call.status == 'missed',
					'list-group-item-warning': call.status == 'pending'
				}"
			>
				<div class="justify-content-between row">
					<div class="col-sm">
						<a class="btn btn-outline-link">
							<i v-if="call.status == 'missed'" class="material-icons"
							>phone_missed</i
							>
							<i v-if="call.status == 'active'" class="material-icons"
							>phone_in_talk</i
							>
							<i v-if="call.status == 'pending'" class="material-icons"
							>notifications_active</i
							>

							<span v-if="call.status == 'missed'">
								Missed call from
								<strong>{{ call.facility_name }}</strong>
							</span>
							<span v-if="call.status == 'pending'">
								Incoming call from
								<strong>{{ call.facility_name }}</strong>
							</span>
							<span v-if="call.status == 'active'">
								On a call
								<strong v-if="call.facility_name"
				>with {{ call.facility_name }}</strong
				>
							</span>
						</a>
					</div>

					<div class="col-sm text-right">
						<span v-show="call.status == 'pending'">
							<a @click.prevent v-if="isMuted(call.pending_id)" class="btn"
						>Muted</a
						>
							<a
						v-else
						href
						v-on:click.prevent="ringRing(false, call.pending_id)"
						class="btn btn-outline-warning mr-2"
						>
								<i class="material-icons">notifications_off</i>Mute
							</a>
						</span>

						<span v-if="call.status == 'missed'">
							<a
						href
						v-on:click.prevent="
									showCallInfo(
										call.name,
										call.contact_info,
										call.additional_notes
									)
								"
						class="btn btn-outline-danger"
						>
								<i class="material-icons">info</i>Info
							</a>
							<a
						style="background-color: rgba(114, 28, 36, .85); border-color: rgb(114, 28, 36);"
						title="Mark this call as completed."
						href
						v-on:click.prevent="
									missedCallComplete(call.missed_call_id, call)
								"
						class="btn btn-danger"
						>
								<i class="material-icons">check</i>Complete
							</a>
						</span>

						<!-- <span v-if="call.status != 'missed'" > -->
						<!-- <a @click.prevent="" class="btn" v-if="myPublisherConnected && call.pending_id == activePendingId">Answered</a> -->
						<a
								v-if="call.status == 'pending'"
								href
								v-on:click.prevent="
								answerVideo(call.pending_id, call.facility_id)
							"
								class="btn btn-primary"
						>
							<i class="material-icons">&#xE62E;</i>Answer
						</a>

						<a
								v-if="call.status == 'active' && call.pending_id"
								href
								v-on:click.prevent="
								answerVideo(call.pending_id, call.facility_id)
							"
								class="btn btn-primary"
						>
							<i class="material-icons">&#xE62E;</i>Reconnect
						</a>

						<a
								v-if="call.status == 'active'"
								href
								v-on:click.prevent="abandonCall(call.active_id, false)"
								class="btn btn-primary"
						>
							<i class="material-icons">&#xE62E;</i>Clear Call
						</a>

						<!-- </span> -->
					</div>
				</div>
			</li>
		</ul>

		<section class="wrap_vdr" v-if="activePendingId || activeRequestId">
			<vue-draggable-resizable
					ref="dragger"
					:w="width"
					:h="height"
					:x="x"
					:y="y"
					:z="99998"
					drag-handle=".drag-handle"
					drag-cancel=".drag-cancel"
					@resizing="onResize"
					@drag="onDrag"
					@dragstop="dragStop"
					:class="'videoViewMode' + videoViewMode"
			>
				<!-- <div class="drag"></div> -->
				<!-- <code>Hello! I'm a flexible component. You can drag me around and you can resize me.<br>
        X: {{ x }} / Y: {{ y }} - Width: {{ width }} / Height: {{ height }}</code>-->
				<twilio-session
						:activePendingId="activePendingId"
						:activeRequestId="activeRequestId"
						:publishVideo="publishVideo"
						:publishAudio="publishAudio"
						@publisherConnected="publisherConnected"
						@hideVideoUI="activePendingId = false"
						@room-created="getSession"
				></twilio-session>

				<div class="drag-handle"></div>

				<div class="vdr_toolbar btn-toolbar drag-cancel">
					<!-- <div class="-btn-group"> -->
					<a
							class="btn btn-pill btn-dark drag-cancel"
							href
							@click.stop.prevent="minifyVideo"
							@touchstart.stop.prevent="minifyVideo"
							data-tooltip="Minify video."
					>
						<i class="material-icons">picture_in_picture</i>
					</a>

					<a
							class="btn btn-pill btn-dark drag-cancel"
							href
							@click.stop.prevent="maximizeVideo"
							@touchstart.stop.prevent="maximizeVideo"
							data-tooltip="Maximize video."
					>
						<i class="material-icons">settings_overscan</i>
					</a>

					<a
							class="btn btn-pill btn-danger drag-cancel"
							href
							@click.stop.prevent="endVideoCall(true)"
							@touchstart.stop.prevent="endVideoCall(true)"
							data-tooltip="End call."
					>
						<i class="material-icons">call_end</i>
					</a>

					<a
							v-if="publishVideo"
							class="btn btn-pill btn-dark drag-cancel"
							href
							@click.stop.prevent="toggleVideo()"
							@touchstart.stop.prevent="toggleVideo()"
							data-tooltip="Turn off my video camera."
					>
						<i class="material-icons">videocam</i>
					</a>

					<a
							v-else
							class="btn btn-pill btn-dark drag-cancel"
							href
							@click.stop.prevent="toggleVideo()"
							@touchstart.stop.prevent="toggleVideo()"
					>
						<i class="material-icons">videocam_off</i>
					</a>

					<a
							v-if="!publishAudio"
							class="btn btn-pill btn-dark drag-cancel"
							href
							@click.stop.prevent="toggleAudio()"
							@touchstart.stop.prevent="toggleAudio()"
					>
						<i class="material-icons">mic_off</i>
					</a>

					<a
							v-else
							class="btn btn-pill btn-dark drag-cancel"
							href
							@click.stop.prevent="toggleAudio()"
							@touchstart.stop.prevent="toggleAudio()"
							data-tooltip="Mute my audio."
					>
						<i class="material-icons">mic</i>
					</a>

					<!-- </div> -->
				</div>
			</vue-draggable-resizable>
		</section>

		<!-- <div style="outline: 1px solid red; margin-top: 200px;" v-html="enumeratedDevices"></div> -->
	</div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";

//import VueDraggableResizable from 'vue-draggable-resizable';

import VueDraggableResizable from "../../components/vue-draggable-resizable";

import Session from "./Session.vue";
import Twilio from "twilio-video";

export default {
	name: "PendingVideoConferences.vue",
	data() {
		return {
			//statusPending: [],
			//statusActive: [],
			//statusMissed: [],

			enumeratedDevices: "",

			hasVideoHardware: false,
			hasAudioInput: false,

			mutedCalls: [],
			// draggable ui
			width: 350,
			height: 200,
			x: 20,
			y: 100,

			int: null,

			publishAudio: true,
			publishVideo: true,

			videoViewMode: "mini", // or max

			myPublisherConnected: false,

			endCall: false,
			session: null,


			//activePendingId: false, // for patient initiate calls
			//activeRequestId: false, // for RT initiated calls
			// pending: [
			//   {
			//     session: 1,
			//     facility_name: 'Alpha'
			//   },
			//   {
			//     session: 2,
			//     facility_name: 'Beta'
			//   }
			// ]
		};
	},

	computed: {
		...mapState(["activePendingId", "activeRequestId"]),
		...mapGetters(["loggedIn", "pendingCalls"]),

		// myOT(){
		//   return this.OT;
		// },

		pending() {
			return this.$store.getters.pendingCalls;
			/*
      return [
        {
          facility_name: "Renowne Medical",
          start: "2018-05-25 21:26:27",
          pending_id: "29",
          active_id: null,
          status: "pending"
        },
        {
          facility_name: "Renowne Medical",
          start: "2018-05-25 20:44:55",
          pending_id: "28",
          active_id: null,
          status: "pending"
        }
      ]
      */
		},
		statusPending() {
			var pendingCalls = this.pending.filter(call => {
				return call.status == "pending";
			});

			return pendingCalls;
		},

		statusActive() {
			var activeCalls = this.pending.filter(call => {
				return call.status == "active";
			});

			return activeCalls;
		},

		statusMissed() {
			var missedCalls = this.pending.filter(call => {
				return call.status == "missed";
			});

			return missedCalls;
		},
	},

	watch: {
		// loggedIn(){
		//
		//   if(this.loggedIn){
		//
		//     console.log('logged IN @ PendingVideo watcher');
		//
		//     this.loopGetPendingCalls();
		//   }
		//
		//   else {
		//
		//     this.ringRing(false);
		//     console.log('logged out @ PendingVideo watcher');
		//     // not logged in
		//     // kill the int
		//     if(this.int != null){
		//       clearInterval(this.int);
		//     }
		//
		//   }
		// },

		pending() {
			this.ringRing(false);

			// status: missed, pending

			if (this.statusPending.length > this.mutedCalls.length) {
				// console.log(
				//   "RING RING. Actually has " +
				//     this.statusPending.length +
				//     " calls that are status: pending"
				// );
				this.ringRing(true);
			} else {
				//console.log("No RING RING. No calls that are status: pending");
			}
		}
	},

	methods: {
		...mapActions(["ajax", "globalAlert", "connectToTwilioSync", "getTwilioSyncKey"]),
		...mapMutations(["updateActiveRequestId", "updateActivePendingId"]),

		hideVideoUI() {
			this.updateActivePendingId(false);
			this.updateActiveRequestId(false);
		},

		clearAllAcitveCalls() {
			var sure = confirm("You are about to clear all active calls.");

			if (sure) {
				// loop through each active calls
				this.statusActive.forEach(element => {
					this.abandonCall(element.active_id, true);
				});

			}
		},

		abandonCall(id, force) {
			const bumBumAudio = new Audio("/static/audio/Error.mp3");
			bumBumAudio.play();

			var sure = true;

			if (!force) {
				sure = confirm("You are about to end this conference/call.");
			}

			if (sure) {
				//call.show =false;

				//this.$set(call, 'show', false);

				// var found = this.pending.find(function(element) {
				// 	return element.active_id === id;
				// });
				//
				// found.show = false;
				//
				// var foundIndex = this.pending.findIndex(function(element) {
				// 	return element.active_id === id;
				// });
				//
				// if (foundIndex !== -1) {
				// 	this.pending.splice(foundIndex, 1);
				// }

				this.updateActivePendingId(false);
				this.updateActiveRequestId(false);

				this.ajax({
					url: "/conference/abandon/" + id,
					//data: {},
					success: () => {
						this.updateActivePendingId(false);
						this.updateActiveRequestId(false);
					}
				});

				this.publishVideo = true;
				this.publishAudio = true;

				this.endCall = true;

				if(this.session) {
					this.session.disconnect();
				}

			}
		},

		showCallInfo(name, contact_info, additional_notes) {
			this.globalAlert({
				title: "Call Info",
				body: name + "<br>" + contact_info + "<br>" + additional_notes
			});
		},

		missedCallComplete(missed_call_id, call_item) {
			this.ajax({
				url: "/conference/missed_call_complete/" + missed_call_id,
				//data: {},
				success: () => {
					//alert('TO DO: update UI after missed_call_complete()');

					//call_item.show = false;

					this.$set(call_item, "show", false);

				}
			});
		},

		publisherConnected() {
			// you are successfully publishing video to this session
			this.myPublisherConnected = true;
		},

		// async loopGetPendingCalls() {
		// 	if (this.loggedIn) {
		// 		this.connectToTwilioSync();
		// 	}
		// },

		// eslint-disable-next-line no-unused-vars
		answerVideo(pendingId, facilityId) {
			//alert('pendingId' + pendingId);

			this.mutedCalls.push(pendingId);

			this.updateActivePendingId(pendingId);
			this.updateActiveRequestId(false);

			// when the call is answered, clear out the pending calls to not show the incoming call banner
			var foundIndex = this.pending.findIndex(function(element) {
				return element.pending_id === pendingId;
			});
			if (foundIndex !== -1) {
				this.pending.splice(foundIndex, 1);
			}

			this.ringRing(false, pendingId); // stops the ringing
			//this.$router.push('/assessment/new');
			//this.$router.push({ name: 'assessmentNew', params: { pendingId: pendingId }});
			this.$router.push("/assessment/new/" + pendingId);
		},

		endVideoCall(showConfirmation) {
			//alert('endVideoCall');

			try {
				const bumBumAudio = new Audio("/static/audio/Error.mp3");
				bumBumAudio.play();
			} catch (err) {
				console.warn("bumBumAudio failed.");
				console.error(err);
			}

			let sure = false;

			if (showConfirmation) {
				sure = confirm("You are about to end this video call.");
			}

			if (sure || !showConfirmation) {
				this.ajax({
					url: "/conference/hang_up/" + this.activePendingId,
					//data: {},
					success: () => {
						this.updateActivePendingId(false);

						this.updateActiveRequestId(false);

						//this.getPendingCalls();
					}
				});

				this.publishVideo = true;
				this.publishAudio = true;

				this.endCall = true;

				if(this.session) {
					this.session.disconnect();
				}



				this.updateActivePendingId(false);
				this.updateActiveRequestId(false);
			}
		},

		isMuted(id) {
			return this.mutedCalls.includes(id);
		},

		getSession(value) {
			this.session = value
		},

		ringRing(loop, callId) {
			//console.log("ringRing(" + loop + ", " + callId + ")");

			// = new Audio("/static/audio/Ring.mp3");
			this.ringRingAudio = new Audio(require("@/assets/audio/Ring.mp3"));

			// remember what calls we muted or answered
			// these should never ting again
			//console.log("callId", callId);
			if (callId) {
				this.mutedCalls.push(callId);
				//console.log(this.mutedCalls);
			}

			if (loop) {
				if (!this.activePendingId) {
					// if there is already a call going on already
					this.ringRingInt = setTimeout(() => {
						this.ringRing(true);
					}, 7000);
				}
			} else {
				// this will mute the ringing

				this.ringRingAudio.pause();
				clearInterval(this.ringRingInt);
				return false;
			}

			this.ringRingAudio.play();
		},

		minifyVideo() {
			//e.stopPropagation();
			var width = 300;
			var height = width * 0.66;
			var x = window.innerWidth - width - 20;
			var y = 40;

			if (this.$refs.dragger) {
				this.$refs.dragger.myMove(width, height, x, y);
			}

			//this.videoViewMode = 'mini';
		},

		maximizeVideo() {
			//e.stopPropagation();
			const nav_height = document.querySelector(".navbar").offsetHeight;

			var width = window.innerWidth - 40;
			var height = width * 0.66;
			height =
				height > window.innerHeight - nav_height - 100
					? window.innerHeight - nav_height - 100
					: height;
			var x = 10;
			var y = 0 + 40;

			console.log("window.innerHeight", window.innerHeight);
			console.log("window.innerWidth", window.innerWidth);
			console.log("width", width);
			console.log("height", height);

			this.$refs.dragger.myMove(width, height, x, y);
		},

		toggleVideo() {
			//e.stopPropagation();
			this.publishVideo = !this.publishVideo;
		},

		toggleAudio() {
			//e.stopPropagation();
			this.publishAudio = !this.publishAudio;
		},

		// draggable ui
		onResize: function(x, y, width, height) {
			this.x = x;
			this.y = y;
			this.width = width;
			this.height = height;

			setTimeout(() => {
				console.log("onResize", this.$refs.dragger.width);

				if (this.$refs.dragger.width <= 450) {
					this.videoViewMode = "mini";
				} else {
					this.videoViewMode = "max";
				}
			}, 1000);
		},
		onDrag: function(x, y) {
			this.x = x;
			this.y = y;

			console.log("onDrag");
		},
		dragStop: function(payload) {
			//this.x = x;
			//this.y = y;

			console.log("onDrag", payload);
		}
	},

	components: {
		"twilio-session": Session,
		VueDraggableResizable
	},

	beforeDestroy() {
		// Unregister the event listener before destroying this Vue instance
		window.removeEventListener("resize", this.minifyVideo);
	},

	mounted() {
		// Register an event listener when the Vue component is ready
		window.addEventListener("resize", this.minifyVideo);

		// window.onbeforeunload = function(e) {
		// 	e = e || window.event;
		//
		// 	this.endVideoCall(true);
		//
		// 	const message =
		// 		"If you leave this browser, you may loose this video session.";
		//
		// 	// For IE and Firefox prior to version 4
		// 	if (e) {
		// 		e.returnValue = message;
		// 	}
		//
		// 	// For Safari
		// 	return message;
		// };

		// add plugin for IE 11

		//OT.upgradeSystemRequirements();
		if (window.location.protocol != "https:") {
			var h = location.href;
			h = h.replace("http", "https");

			if (process.env.NODE_ENV == "development") {
				console.log("development");
			} else {
				window.location.replace(h);
			}

			this.globalAlert({
				title: "Incompatible Protocol",
				//okMethod: goToSecureURL(),
				//closeMethod: goToSecureURL(),
				body:
					'The video component of this application requires a secure browser connection. <a href="' +
					h +
					'">Go to secure URL</a>'
			});
		}

		if (Twilio.isSupported) {
			console.log("Twilio.checkSystemRequirements() = true");
		} else {
			console.log("Twilio.checkSystemRequirements() = false");

			this.globalAlert({
				title: "Incompatible Browser",
				body:
					"The video component of this application requires Google Chrome, Firefox, Internet Explorer 11 (with plugin) or Edge 17 with Windows 10 April update."
			});
		}


		// this.loopGetPendingCalls();

		if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
			console.log("enumerateDevices() not supported.");
			return;
		}

		// List cameras and microphones.

		this.enumeratedDevices = "";

		this.hasVideoHardware = false;
		this.hasAudioInput = false;

		navigator.mediaDevices
			.enumerateDevices()
			.then(devices => {
				devices.forEach(device => {
					console.log(device);

					this.enumeratedDevices +=
						device.kind +
						": " +
						device.label +
						" id = " +
						device.deviceId +
						"<br>";

					if (device.kind == "videoinput") {
						this.hasVideoHardware = true;
					}

					if (device.kind == "audioinput") {
						this.hasAudioInput = true;
					}
				});

				if (this.hasVideoHardware == false) {
					//this.globalAlert();

					this.globalAlert({
						title: "Missing Video Camera",
						body:
							"Your browser is not able to access a video camera. Close any other applications that may be using your video camera."
					});
				}

				if (this.hasAudioInput == false) {
					//this.globalAlert();

					this.globalAlert({
						title: "Missing Audio Input",
						body:
							"Your browser is not able to access an audio input source. Close any other applications that may be using your microphone hardware."
					});
				}
			})
			.catch(err => {
				console.log(err.name + ": " + err.message);
				this.enumeratedDevices += err.name + ": " + err.message;
			});
	}
};
</script>

<style lang="scss">
.wrap_vdr {
  position: fixed;
  top: 70px;
  display: block;
  z-index: 99999;
}

.vdr {
  background-color: #6c757d;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
  transition-duration: 0.5s;
  border-radius: 10px;

  .drag {
	height: 44px;
  }

  .vdr_toolbar {
	position: absolute;
	display: flex;
	bottom: 10px;
	left: 10px;
	right: 10px;
	//background-color: #4e555b;
	border-radius: 0 0 10px 10px;
	justify-content: space-between;

	opacity: 0.4;
	transition-duration: 0.3s;

	z-index: 9999;

	a {
		z-index: 10000;
	}
  }
}

.vdr:hover {
  .vdr_toolbar {
	opacity: 1;
  }
}

.vdr.active {
  box-shadow: none;
  transition-duration: 0ms;
}

.squareBubble {
  position: fixed;
  background: #343a40 !important;
  color: white;
  padding: 1.2rem;
  font-size: 1rem;
  bottom: -220px;
  //bottom: 20px;
  width: auto;
  z-index: 999;
  opacity: 0;
  left: 20px;
  transition-delay: 0.5s;
  transition-duration: 0.9s;
  padding-left: 60px;

  .spinner {
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 15px;
	display: block;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin: -15px auto auto 0;
	border: 3px solid #ffffff;
	//border-top-color: rgba(255,255,255,1);
	//border-right-color: rgba(255,255,255,.5);
	//border-bottom-color: rgba(255,255,255,.25);
	//border-left-color: rgba(255,255,255,.125);
	border-left-color: transparent;
	border-top-color: transparent;
	animation: spin 1s linear infinite;
  }
}

.drag-handle {
  //background-color: rgba(red, 0.5);
  position: absolute;
  top: 2px;
  right: 2px;
  left: 2px;
  bottom: 55px;
  z-index: 999999;
}

.showSquareBubble {
  opacity: 0.5;
  bottom: 20px;
  transition-delay: 0s;
}
</style>
