//Login.vue //
https://paweljw.github.io/2017/09/vue.js-front-end-app-part-3-authentication/ //
to redirect oafter successful login use
this.$router.replace(this.$route.query.redirect || '/authors')

<template>
	<div class="text-center">
		<form class="form-signin" @submit.prevent="submitForm">
			<!-- <img class="mb-4" src="https://getbootstrap.com/assets/brand/bootstrap-solid.svg" alt="" width="72" height="72"> -->
			<!-- <img style="display: block;" class="logo" width="200" src="../assets/rtNOW-logo.png"> -->
			<h1 class="text-left mb-4 mt-5">Please sign in</h1>
			<label for="inputEmail" class="sr-only">Email address</label>
			<input
				v-model="email"
				type="email"
				id="inputEmail"
				class="form-control form-control-lg"
				placeholder="Email address"
				required=""
				autofocus=""
			/>
			<label for="inputPassword" class="sr-only">Password</label>
			<input
				v-model="password"
				type="password"
				id="inputPassword"
				class="form-control form-control-lg"
				placeholder="Password"
				required=""
			/>
			<!-- <div class="checkbox mb-3">
				<label>
					<input type="checkbox" value="remember-me"> Remember me
				</label>
			</div> -->
			<button
				class="mt-4 btn btn-lg btn-primary btn-block"
				type="submit"
				:class="{ 'btn-loading': ajaxLoader }"
			>
				Sign in
			</button>

			<router-link to="/password/forgot" class="btn btn-lg mt-3"
				>Forgot Password?</router-link
			>

			<p class="mt-5 mb-3 text-muted">© {{new Date().getFullYear()}}</p>
		</form>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
	data() {
		return {
			email: "",
			password: ""
			//ajaxLogin: false
		};
	},

	computed: {
		...mapGetters([
			"loggedIn",
			"ajaxLoader" // context.state.ajaxLoader = true;
		]),

		moreComputedProperties() {
			return "foo";
		}
	},

	watch: {
		loggedIn(newVal) {
			if (newVal) {
				//this.ajaxLogin = false;

				this.email = "";
				this.password = "";
				this.$router.push(this.$store.state.destination);
			}
		}
	},

	methods: {
		...mapActions([
			"login"
			//'ajax'
		]),

		submitForm() {
			console.log(this.email);
			console.log(this.password);

			//this.ajaxLogin = true;

			//alert(this.$store.state.loggedIn);
			this.login({
				email: this.email,
				password: this.password
			}).then(() => {
				//this.ajaxLogin = false;
				// if(this.loggedIn){
				// 	this.$router.push(this.$store.state.destination);
				// }
				// if there is no error go to home page
				//if(this.$store.loggedIn){
				//this.$router.push(this.$store.state.destination);
				//}
			});
		} //submitForm
	},

	mounted() {
		this.email = "";
		this.password = "";

		this.$store.dispatch("logout");
	}
};
</script>

<style lang="scss">
.form-signin {
	width: 100%;
	max-width: 430px;
	padding: 15px 10px;
	margin: 0 auto;

	h2 {
	}
}
.form-signin .checkbox {
	font-weight: 400;
}
.form-signin .form-control {
	position: relative;
	box-sizing: border-box;
	height: auto;
	//padding: 10px;
	//font-size: 16px;
}
.form-signin .form-control:focus {
	z-index: 2;
}
.form-signin input[type="email"] {
	margin-bottom: -1px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
	margin-bottom: 10px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
</style>
