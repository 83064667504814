<template>
	<div v-if="session" id="session" class="sessionEl" @error="errorHandler">
		<publisher
			:session="session"
			@publisherConnected="publisherConnected"
			:localTrack="localTrack"
			@error="errorHandler"
			:publishVideo="publishVideo"
			:publishAudio="publishAudio"
			:key="publisherReload"
		></publisher>

		<div
			class="subscribersEl"
			:data-count="streams.length"
			v-show="session"
		>
			<div
				v-for="(stream, index) in streams"
				:key="stream"
				data-id="subscribers"
				class="innerSubscriberEl"
				:style="subStyle"
			>
				<subscriber
						@error="errorHandler"
						:key="subscriberReload"
						:session="session"
						:indexNumber = index
						v-bind:stream="stream"
						ref="subscriber"
				></subscriber>
			</div>
		</div>

		<div class="video_attendee_info fixed-bottom">
			<ul>
				<li>
					<a :class="{ active: sessionSignalPopover.show }">
						<i class="material-icons">info</i>
						<div
							class="popover fade show bs-popover-right"
							role="tooltip"
							style="will-change: transform;"
							x-placement="right"
						>
							<div class="arrow" style="top: 16px;"></div>
							<h3 class="popover-header"></h3>
							<div class="popover-body">
								<!--								<pre>{{ conference.recipients }}</pre>-->
								<p
									v-if="conference.recipients && !conference.recipients.length"
								>
									Waiting for others to connect...
								</p>
								<div v-if="!recipient_location_array.length">
									<div
										v-for="(row, n) in conference.recipients"
										:key="'conference-recipients-' + n"
									>
										{{ row.name }} -
										{{ row.recipient_location }}
									</div>
								</div>
								<div
									v-for="(row, n) in recipient_location_array"
									:key="'recipient_location_array-' + n"
								>
									{{ row.name }} - {{ row.location }}
									{{ row.recipient_location }}
								</div>
							</div>
						</div>
					</a>
				</li>
			</ul>
		</div>

		<session-video-settings
			:publishersAudioInputsArray="publishersAudioInputsArray"
			:publishersVideoInputsArray="publishersVideoInputsArray"
			:currentIosAudioInputDeviceId="currentIosAudioInputDeviceId"
			:iosAudioInputDevices="iosAudioInputDevices"
			:currentAudioDeviceId="currentAudioDeviceId"
			:publisher="publisherCopy"
			@updateIosAudioInputDevice="updateIosAudioInputDevice"
		/>
	</div>
</template>

<script>
import Publisher from "./Publisher.vue";
import Subscriber from "./Subscriber.vue";
import {connect, createLocalTracks, createLocalVideoTrack, LocalDataTrack} from "twilio-video";

import { mapActions } from "vuex";
import SessionVideoSettings from "./SessionVideoSettings";

export default {
	name: "Session.vue",
	components: {
		SessionVideoSettings,
		"publisher": Publisher,
		"subscriber": Subscriber
	},

	props: ["activePendingId", "activeRequestId", "publishVideo", "publishAudio", "endCall"],

	data: () => ({
		//recipient_location: "Waiting for others to connect...",
		recipient_location_array: [],
		streams: [],
		session: null,
		debugJSON: {},
		conference: {
			recipients: []
		},
		sessionSignalPopover: {
			show: true
		},
		sessionSignalModal: {
			show: false,
			id: null // the id of the alert to remove
		},
		localTrack: [],
		//publisher: null,
		publisherReload: 0,
		subscriberReload: 0,
		publisherCopy: null,
		publishersAudioInputsArray: null,
		currentAudioDeviceId: null,
		publishersVideoInputsArray: null,

		currentIosAudioInputDeviceId: "aaaaaaaaaa",
		iosAudioInputDevices: [
			{ label: "Fake Stethoscope", deviceId: "aaaaaaaaaa" },
			{ label: "Fake Internal Microphone", deviceId: "bbbbbbbbbb" }
		],
	}),

	computed: {
		streamWidth() {
			return 100 / this.streams.length + "%";
		},
		subStyle() {
			var s = "";

			if (this.streams.length < 4) {
				s = "width: " + 100 / this.streams.length + "%; min-height: 100%;";
			}
			//
			else if (this.streams.length === 4) {
				s = "height: 50%; min-width: 50%;";
			}
			//
			else if (this.streams.length < 7) {
				s = "height: 33.333333%; min-width: 50%;";
			}
			//
			else if (this.streams.length < 9) {
				s = "height: 25%; min-width: 50%;";
			}

			return s;
		},
		twilioStreams() {
			return this.streams
		}
	},
	created() {
		window.addEventListener('beforeunload', this.handleWindowClose);
	},
	mounted() {
		this.ajax({
			/*
        activePendingId is used for incoming calls from iPad
        activeRequestId is used for standalone, RT-initiated calls, CoreLink
      */
			url: this.activePendingId
				? "/conference/get/" + this.activePendingId // for original incoming calls from the iPad app
				: "/conference/request_connection/" + this.activeRequestId, // for standalone calls, CoreLink
			//data: {},
			success: async json => {
				this.debugJSON = json.conference;

				// for testing
				// json.app_id  = '46096082';
				// json.session = '1_MX40NjA5NjA4Mn5-MTUyMzk5NTU5MDc3M35ldkdhRU4rTXV2RGt5S2d4Y1MxTGFlbll-UH4';
				// json.token   = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzk0MjZlMjY0MDQ4NmRiNTBjZTdkMDZjMDc3ZmMzOTgyLTE2Njk3NTE1ODIiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJtYW5keSIsInZpZGVvIjp7fX0sImlhdCI6MTY2OTc1MTU4MiwiZXhwIjoxNjY5NzU1MTgyLCJpc3MiOiJTSzk0MjZlMjY0MDQ4NmRiNTBjZTdkMDZjMDc3ZmMzOTgyIiwic3ViIjoiQUM4ODZiYjgwZmM1ZDJhNzQ0NzE1NGJiMGQxMmVmZTNkNCJ9.fplaan6KoRltKXYiRV23vON0-woEA-jHSlRiYJ_-GZ8';

				if (json.session) {
					this.conference = json.conference;

					if (json.conference.recipient && json.conference.recipient.location) {
						this.sessionSignalPopover.show = true;

						console.log("json.conference...", json.conference);


						setTimeout(() => {
							this.sessionSignalPopover.show = false;
						}, 8000);
					}
					//
					else {
						this.sessionSignalPopover.show = false;
					}

					this.publishAudio = true
					this.publishVideo = true

					// Request just the default audio track
					if(this.localTrack.length === 0) {
						this.localTrack = await createLocalTracks({
							audio: this.publishAudio,
							video: this.publishVideo,
							// logLevel: 'debug'
						}).then(function (localTracks) {
							return localTracks
						});
					}

					this.streams = []

					const dataTrack = new LocalDataTrack();
					this.localTrack.push(dataTrack);

					await connect(json.conference.token, {
						name: json.conference.session,
						tracks: this.localTrack,
						// preferredVideoCodecs: ['VP8'],
						// logLevel: 'debug',
						// bandwidthProfile: {
						// 	video: {
						// 		mode: 'presentation',
						// 		dominantSpeakerPriority: 'high',
						// 		trackSwitchOffMode: 'detected',
						// 		clientTrackSwitchOffControl: 'manual',
						// 		contentPreferencesMode: 'auto',
						// 	}
						// }
					}).then(room => {

						const localParticipant = room.localParticipant;
						console.log(`Connected to the Room as LocalParticipant "${localParticipant.identity}"`);

						const chaChingAudio = new Audio("/static/audio/Notification.mp3");
						chaChingAudio.play();

						this.session = room;
						this.$emit('room-created', room)

						// show the tracks that the participant eventually publishes (Attach the Participant's Media to a <div> element.)
						room.on('participantConnected', participant => {
							console.log(`Participant "${participant.identity}" connected`);

							let counterNumber = Array.from(participant.tracks.values()).length;

							let counter1 = 0;
							let participantTrack1 = []
							participant.tracks.forEach(publication => {
								counter1++;
								if(publication.isSubscribed) {

									participantTrack1.push(publication.track)

									publication.track.on('enabled', () => {
										this.trackEnabled(publication.track)
									});

									publication.track.on('disabled', () => {
										this.trackDisabled(publication.track)
									});

								}
								if(counter1 === counterNumber && this.searchForArray(this.streams, participantTrack1) === -1 && participantTrack1.length > 0) {
									this.streams.push(participantTrack1)
								}


							});

							let counter2 = 0;
							let participantTrack2 = []
							participant.on('trackSubscribed', track => {
								counter2++;
								participantTrack2.push(track)

								if(counter2 === counterNumber && this.searchForArray(this.streams, participantTrack2) === -1) {
									this.streams.push(participantTrack2)
								}

								track.on('enabled', () => {
									this.trackEnabled(track)
								});
								track.on('disabled', () => {
									this.trackDisabled(track);
								});


							});
						});

						let self = this;

						room.on('trackUnpublished', track => {
							self.trackUnpublished(track);
						});

						room.on('trackPublished', track => {
							if(track.isSubscribed){
								self.trackPublished(track)
							} else {
								track.on('subscribed', track => {
									self.trackPublished(track)
								})
							}
						});


						// For RemoteParticipants that are already in the Room, we can attach their RemoteTracks by iterating over the Room's participants:
						room.participants.forEach(participant => {
							// using counter to make sure we grab both audio and video tracks after it was subscribed

							let counterNumber = Array.from(participant.tracks.values()).length;

							let counter1 = 0;
							let participantTrack1 = []
							participant.tracks.forEach(publication => {
								counter1++;
								if(publication.track) {

									participantTrack1.push(publication.track)

									publication.track.on('enabled', () => {
										this.trackEnabled(publication.track)
									});

									publication.track.on('disabled', () => {
										this.trackDisabled(publication.track)
									});

								}


								if(counter1 === counterNumber && this.searchForArray(this.streams, participantTrack1) === -1 && participantTrack1.length > 0) {
									this.streams.push(participantTrack1)
								}

							});


							let counter2 = 0
							let participantTrack2 = [];
							participant.on('trackSubscribed', track => {
								counter2++;
								participantTrack2.push(track)
								if(counter2 === counterNumber && this.searchForArray(this.streams, participantTrack2) === -1) {
									this.streams.push(participantTrack2);
								}
								track.on('enabled', () => {
									this.trackEnabled(track)
								});

								track.on('disabled', () => {
									this.trackDisabled(track)
								});

							});


						});


						room.on('participantDisconnected', participant => {
							console.log("Participant '" + participant.identity + "' left the room");

							let idx = null;

							participant.tracks.forEach(track => {
								for (let i = 0; i < this.streams.length; i++) {
									this.streams[i].forEach(function (item) {
										if (item.name === track.trackName) {
											idx = i;
										}
									})
									if (idx !== null) {
										break
									}
								}
							});

							console.log('idx', idx)

							if (idx > -1) {
								this.streams.splice(idx, 1);
								// if (this.streams.length < 1 && this.session !== 0) {
								// 	// this means everyone else has hung up
								// 	// and I'm the only one left in the room (session)
								// 	alert("All other parties have left this video chat session.");
								// 	//this.$set( this, 'activePendingId', false);
								// 	this.$emit("hideVideoUI");
								// }
							}

						});

						if (this.endCall === true) {
							room.disconnect();
							this.localTrack.forEach(track => {
								if(track.kind !== 'data' && track.kind === 'video'){
									track.stop();
									track.unpublish();
								}
							})
						}

						room.on("disconnected", event => {
							console.log("++++++++ session Disconnected", event);

							room.localParticipant.videoTracks.forEach(publication => {
								publication.track.stop();
								publication.unpublish();
							});

							room.localParticipant.audioTracks.forEach(publication => {
								publication.track.stop();
								publication.unpublish();
							});

							room.localParticipant.unpublishTracks(this.localTrack);

							room.localParticipant.tracks.forEach(track => {
								track.unpublish();
							});

							this.localTrack.forEach(track => {
								if(track.kind !== 'data' && track.kind === 'video'){
									track.stop();
								}
								if(track.kind !== 'data' && track.kind === 'audio'){
									track.disable();
								}
							})

							this.session = 0;

							this.$emit("sessionDisconnected");

						});


					}, error => {
						console.error(`Unable to connect to Room: ${error.message}`);
					});

				}
			}
		});
	},


	methods: {
		...mapActions([
			"ajax",
			"globalAlert"
			//'setDestination'
		]),

		// gatherPublisherAudioInputs(audioInputsArray) {
		// 	this.publishersAudioInputsArray = audioInputsArray;
		// },

		updateIosAudioInputDevice(newDevice) {
			// send a signal to the iOS device

			//alert("TO DO, set currentIosAudioInputDeviceIdComputed: " + newDevice);
			let data = {
				signalType: "updateIosAudioInputDevice",
				newDevice: newDevice
			};
			this.session.signal(
				{
					data: JSON.stringify(data)
					//type: "updateIosAudioInputDevice"
				},
				function(error) {
					if (error) {
						console.error(
							"signal error (" + error.name + "): " + error.message
						);
					} else {
						console.log("signal sent.", data);
					}
				}
			);
		},

		closeModal() {
			this.sessionSignalModal.show = false;
		},
		//
		publisherConnected(publisher) {
			console.log("publisherConnected @ Session.vue");
			console.dir(publisher);

			this.localTracks = publisher

			this.publisherCopy = publisher;

			navigator.mediaDevices.enumerateDevices().then((err, devices) => {
				// video input
				if(devices) {
					this.publishersVideoInputsArray = devices.filter(
						device => device.kind === "videoInput"
					);

					// audio input
					this.publishersAudioInputsArray = devices.filter(
						device => device.kind === "audioInput"
					);
				}

				// Find the right starting index for cycleMicrophone
				// eslint-disable-next-line no-unused-vars
					if(this.publishersAudioInputsArray) {
						this.publishersAudioInputsArray.forEach((device, idx) => {
							console.log(idx)
							if (publisher && device.label === publisher.getAudioSource().label) {
								this.currentAudioDeviceId = device.deviceId;
							}
						});
					}

			});

			this.$emit("publisherCompleted");
		},
		trackPublished(track) {
			console.log('track published', track)
			this.streams.forEach(function (stream) {
				stream = stream.filter(item => item !== null);
				const hasVideo = stream.some(track => track.kind === 'video');
				if(!hasVideo) {
					stream.push(track.track)
				}
			})
			this.subscriberReload++;
			this.$emit('track-published', track);

		},
		trackUnpublished(track) {
			// working around twilio not sending participant disconnected event in timely manner
			if(track.kind === 'data') {
				console.log('data track unpublished meaning call is ended', track)
				for (let i = 0; i < this.streams.length; i++) {
					for (let j = 0; j < this.streams[i].length; j++) {
						if (this.streams[i][j] && this.streams[i][j].name === track.trackName) {
							this.streams.splice(i, 1);
						}
					}
				}
			} else {
				console.log('track unpublished', track)
				let idx = null
				let isMuted = null;
				for (let i = 0; i < this.streams.length; i++) {
					for (let j = 0; j < this.streams[i].length; j++) {
						if (this.streams[i][j] && this.streams[i][j].name === track.trackName) {
							isMuted = true
							idx = i
							this.streams[i].splice(j, 1)
						}
					}
				}
				if(isMuted) {
					this.$emit('track-unpublished', idx, track);
				}
			}

		},
		trackEnabled(track) {
			console.log('track enabled', track)
			let idx = null
			let twilioTrack = null;
			let isMuted = null;
			for (let i = 0; i < this.streams.length; i++) {
				this.streams[i].forEach(function (item) {
					if (item.name === track.name) {
						isMuted = false
						idx = i
						twilioTrack = track
					}
				})
			}
			if(!isMuted) {
				this.$emit('track-enabled', twilioTrack, idx);
			}
		},
		trackDisabled(track) {
			console.log('track disabled', track)
			let idx = null
			let twilioTrack = null;
			let isMuted = null;
			for (let i = 0; i < this.streams.length; i++) {
				this.streams[i].forEach(function (item) {
					if (item.name === track.name) {
						isMuted = true
						idx = i
						twilioTrack = track
					}
				})
			}
			if(isMuted) {
				this.$emit('track-disabled', twilioTrack, idx);
			}
		},
		searchForArray(haystack, needle){
			var i, j, current;
			for(i = 0; i < haystack.length; ++i){
				if(needle.length === haystack[i].length){
					current = haystack[i];
					for(j = 0; j < needle.length && needle[j] === current[j]; ++j);
					if(j === needle.length)
						return i;
				}
			}
			return -1;
		},

		errorHandler(err) {
			console.warn(err);

			this.globalAlert({
				title: "Twilio Error",
				body: err.message + "<br><pre>" + err + "</pre>"
			});

			//this.session.disconnect();

			//this.$emit('sessionErrored', err);
		},
		handleWindowClose() {
			this.session.disconnect();

			this.session.localParticipant.tracks.forEach(publication => {
				publication.unpublish();
				if(publication.kind !== 'data') {
					publication.track.stop();
					publication.unpublish();
				}
			});
		},
		async createVideoTrack() {
			await createLocalVideoTrack().then(localVideoTrack => {
				this.localTrack.push(localVideoTrack);
				this.publisherReload++;
				return this.session.localParticipant.publishTrack(localVideoTrack);
			}).then(publication => {
				console.log('Successfully unmuted your video:', publication);
			});
		}
	},

	watch: {
		publishAudio(newVal) {
			if (newVal === false) {
				this.session.localParticipant.audioTracks.forEach(publication => {
					publication.track.disable();
				});
				console.log("audio disabled")
			} else if (newVal === true) {
				this.session.localParticipant.audioTracks.forEach(publication => {
					publication.track.enable();
				});
				console.log("audio enabled")
			}
		},
		publishVideo(newVal) {
			if (newVal === false) {
				this.session.localParticipant.videoTracks.forEach(publication => {
					// muting video by disabling it
					publication.track.disable();
					// muting video by unpublishing it
					publication.track.stop();
					publication.unpublish();
					// finding out the video track in local track array and deleting it to mute the video
					let idx = null;
					for (let i = 0; i < this.localTrack.length; i++) {
						if(this.localTrack[i].name === publication.track.name) {
							idx = i
						}
						if (idx !== null) {
							break
						}
					}
					if(idx > -1) {
						this.localTrack.splice(idx, 1);
					}
				});
				console.log("video disabled")
			} else if (newVal === true) {
				this.session.localParticipant.videoTracks.forEach(publication => {
					// handling video enable using enable method
					publication.track.enable();
					// handling video enable using publish method
				});

				this.createVideoTrack();
				console.log("video enabled")
			}
		},
  },

	beforeDestroy() {
		window.addEventListener('beforeunload', this.handleWindowClose);
	}
};
</script>

<style lang="scss">
* {
	//outline: 1px solid pink;
}

.sessionEl {
	width: 100%;
	height: 100%;

	border-radius: 10px;

	.subscribersEl {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;

		display: flex;
		flex-wrap: wrap;

		//border-radius: 10px;

		//display: block;

		.innerSubscriberEl {
			flex: 1;
			//border: 2px solid blue;
			//width: 50%;
			height: 100%;
			width: 100%;

			display: inline-block;

			margin: 0 0 -6px 0;
			padding: 0;

			.innerInnerSubscriberEl {
				height: 100%;

				margin: 0;
				padding: 0;
			}

			.OT_subscriber {
				//border-radius: 10px;
			}
		}
	}
}


.OT_subscriber {
	float: left;
}
.OT_publisher {
	float: left;
}
</style>
