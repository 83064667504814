// Welcome.vue

<template>
	<div class="container">
		<h1 class="mt-5 pt-1">Welcome</h1>

		<router-link tag="a" class="btn btn-lg btn-primary" exact to="/login">
			Sign in</router-link
		>
	</div>
</template>

<script>
export default {
	data() {
		return {
			msg: "Welcome to Your Vue.js App"
		};
	}
};
</script>

<style lang="scss"></style>
