<template>
	<section class="video_settings">
		<div class="fixed-bottom">
			<a
				href
				@click.stop.prevent="videoSettingModal.show = true"
				@touchstart.stop.prevent="videoSettingModal.show = true"
				><i class="material-icons">settings</i></a
			>
		</div>

		<app-modal :modal="videoSettingModal" @closeModal="closeVideoSettingModal">
			<template slot="title">Settings</template>
			<form>
				<pre>
					publishersAudioInputsArray: {{ publishersAudioInputsArray }}
				</pre
				>
				<div class="form-group">
					<label>Select Audio Source</label>
					<select
						class="custom-select form-control-lg"
						v-model="currentAudioDeviceIdComputed"
					>
						<option value>- Select Audio -</option>
						<option
							v-for="device in publishersAudioInputsArray"
							:value="device.deviceId"
							:key="'publishersAudioDevice-' + device.deviceId"
							>{{ device.label }}</option
						>
					</select>
				</div>
				<div class="form-group" v-if="false">
					<label>Select iOS Audio Input</label>
					<select
						class="custom-select form-control-lg"
						v-model="currentIosAudioInputDeviceIdComputed"
					>
						<option value>- Select Listening Device -</option>
						<option
							v-for="input in iosAudioInputDevices"
							:value="input.deviceId"
							:key="'iosAudioInputDevices-' + input.deviceId"
							>{{ input.label }}</option
						>
					</select>
				</div>
				<div
					v-if="
						publishersVideoInputsArray && publishersVideoInputsArray.length > 1
					"
				>
					<label>Select the next available camera.</label>
					<div>
						<a
							:disabled="
								publishersVideoInputsArray &&
									publishersVideoInputsArray.length <= 1
							"
							class="btn btn-outline-primary"
							href
							@click.prevent="cycleVideo"
							><i class="material-icons">videocam</i>Cycle Video Camera</a
						>
					</div>
				</div>
				<small v-if="publishersVideoInputsArray" class="form-text text-muted"
					>You have {{ publishersVideoInputsArray.length }} available video
					camera{{
						publishersVideoInputsArray.length === 1 ? "" : "s to choose from"
					}}.</small
				>
			</form>
		</app-modal>
	</section>
</template>

<script>
export default {
	name: "SessionVideoSettings",
	props: [
		"publishersAudioInputsArray",
		"publishersVideoInputsArray",
		"currentAudioDeviceId",
		"publisher",
		"currentIosAudioInputDeviceId",
		"iosAudioInputDevices"
	],
	data() {
		return {
			videoSettingModal: {
				show: false
			}
		};
	},
	computed: {
		currentIosAudioInputDeviceIdComputed: {
			get: function() {
				return this.currentIosAudioInputDeviceId;
			},
			set: function(newValue) {
				this.$emit("updateIosAudioInputDevice", newValue);
			}
		},
		currentAudioDeviceIdComputed: {
			get: function() {
				return this.currentAudioDeviceId;
			},
			set: function(newValue) {
				//this.$emit("publisherSetAudioSource", newValue);

				//publisherSetAudioSource(newAudioDeviceId) {
				//this.currentAudioDeviceId = newAudioDeviceId;

				console.log(
					"publisherSetAudioSource old",
					this.publisher.getAudioSource()
				);
				console.log("publisherSetAudioSource newAudioDeviceId", newValue);

				this.publisher.setAudioSource(newValue);

				console.log(
					"publisherSetAudioSource new",
					this.publisher.getAudioSource()
				);

				//},
			}
		}
	},
	methods: {
		closeVideoSettingModal() {
			this.videoSettingModal.show = false;
		},
		cycleVideo() {
			this.publisher.cycleVideo().then(console.log);
		}
		//publisherSetAudioSource() {

		//}
	}
};
</script>

<style scoped></style>
