import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//import HelloWorld from '@/components/HelloWorld'

import Welcome from "../components/Welcome.vue";
//import Dashboard from "../components/Dashboard.vue";
import Login from "../components/Login.vue";
//import Logout from "../components/Logout.vue";
//import PasswordForgot from "../components/PasswordForgot.vue";
//import PasswordReset from "../components/PasswordReset.vue";
//import UserList from "../components/user/UserList.vue";
//import UserForm from "../components/user/UserForm.vue";
//import Schedule from '../components/Schedule.vue'
//import AssessmentNew from "../components/assessment/AssessmentNew.vue";
//import ClientList from "../components/client/ClientList.vue";
//import FacilityList from "../components/facility/FacilityList.vue";

// lazy load component
const FacilityList = () =>
	import(
		/* webpackChunkName: "facility" */ "../components/facility/FacilityList.vue"
	);
const ClientList = () =>
	import(
		/* webpackChunkName: "client" */ "../components/client/ClientList.vue"
	);
const AssessmentNew = () =>
	import(
		/* webpackChunkName: "assessment" */ "../components/assessment/AssessmentNew.vue"
	);

const FlexFormInit = () =>
	import(/* webpackChunkName: "flex-form" */ "../views/FlexForm/Init.vue");

const FlexFormListForms = () =>
	import(/* webpackChunkName: "flex-form" */ "../views/FlexForm/ListForms.vue");

const UserList = () =>
	import(/* webpackChunkName: "user" */ "../components/user/UserList.vue");
const UserForm = () =>
	import(/* webpackChunkName: "user" */ "../components/user/UserForm.vue");
const PasswordReset = () =>
	import(/* webpackChunkName: "password" */ "../components/PasswordReset.vue");
const PasswordForgot = () =>
	import(/* webpackChunkName: "password" */ "../components/PasswordForgot.vue");
const Logout = () =>
	import(/* webpackChunkName: "logout" */ "../components/Logout.vue");
const Dashboard = () =>
	import(/* webpackChunkName: "dashboard" */ "../components/Dashboard.vue");
const Schedule = () =>
	import(/* webpackChunkName: "schedule" */ "../components/Schedule.vue");
const AlertsList = () =>
	import(
		/* webpackChunkName: "alerts" */ "../components/alerts/AlertsList.vue"
	);
const AlertForm = () =>
	import(
		/* webpackChunkName: "alerts" */ "../components/alerts/AlertsForm.vue"
	);
const ConferenceList = () =>
	import(
		/* webpackChunkName: "conference-list" */ "../components/conference/ConferenceList.vue"
	);
const PhoneLogs = () =>
	import(/* webpackChunkName: "phone-logs" */ "../views/PhoneLogs.vue");

const KitchenSink = () =>
	import(/* webpackChunkName: "kitchen-sink" */ "../views/KitchenSink.vue");

//import AlertsList from '../components/alerts/AlertsList.vue'
//import AlertForm from '../components/alerts/AlertsForm.vue'

//Vue.use(Router)

const routes = [
	{
		path: "/welcome",
		component: Welcome
	},
	{
		path: "/kitchensink",
		component: KitchenSink
	},
	{
		path: "/phone/logs",
		component: PhoneLogs,
		name: "phoneLogs"
	},
	{
		path: "/reports",
		component: Dashboard,
		name: "dashBoard"
	},
	{
		path: "/login",
		component: Login,
		name: "logIn"
	},
	{
		path: "/logout",
		component: Logout,
		name: "logOut"
	},
	{
		path: "/password/forgot",
		component: PasswordForgot
	},
	{
		path: "/password/reset/:token",
		component: PasswordReset
	},

	// USERS
	{
		path: "/users", // rtnow-admin, facility-admin, rts
		component: UserList,
		name: "userList"
	},
	{
		path: "/user/add/:email",
		component: UserForm,
		name: "userFormAdd",
		props: true
	},
	{
		path: "/user/edit/:id",
		component: UserForm,
		name: "userFormEdit",
		props: true
	},

	// Schedule
	{
		path: "/schedule",
		name: "schedule",
		component: Schedule
	},
	{
		path: "/assessment/new/:pendingCallId",
		name: "assessmentNew",
		component: AssessmentNew,
		props: true
	},
	{
		path: "/facilities",
		name: "facilities",
		component: FacilityList
	},
	{
		path: "/conferences",
		name: "conferenceList",
		component: ConferenceList
	},

	// Alerts
	{
		path: "/alerts",
		component: AlertsList,
		name: "alertsList"
	},
	{
		path: "/alert/add",
		component: AlertForm,
		name: "alertFormAdd",
		props: true
	},
	{
		path: "/alert/edit/:id",
		component: AlertForm,
		name: "alertFormEdit",
		props: true
	},

	{
		path: "/form/list",
		component: FlexFormListForms,
		name: "FlexFormListForms",
		props: true
	},
	{
		path: "/form/init/:formTemplateId",
		component: FlexFormInit,
		name: "FlexFormInit",
		props: true
	},

	{
		path: "/clients",
		name: "clients",
		component: ClientList
	},

	// catch all
	{
		path: "*",
		component: Login
	}
];

const router = new VueRouter({
	routes,
	mode: "hash" // or 'hisotry'
});

export default router;
