<template>
	<nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
		<router-link class="navbar-brand pt-0" :to="{ name: 'dashBoard' }">
			<img :src="logoTrans" height="28" />
		</router-link>

		<button
			v-on:click.prevent="collapsed = !collapsed"
			class="navbar-toggler"
			:class="{ collapsed: collapsed }"
			type="button"
			data-toggle="collapse"
			data-target="#navbarsExampleDefault"
			aria-controls="navbarsExampleDefault"
			aria-expanded="false"
			aria-label="Toggle navigation"
		>
			<span class="navbar-toggler-icon"></span>
		</button>

		<div
			class="navbar-collapse collapse"
			:class="{ show: !collapsed }"
			id="navbarsExampleDefault"
			style
		>
			<ul class="navbar-nav mr-auto">
				<router-link
					v-if="iAmAdmin"
					tag="li"
					:class="{ hide: !loggedIn }"
					class="nav-item"
					active-class="active"
					exact
					:to="{ name: 'dashBoard' }"
				>
					<a class="nav-link">Reports</a>
				</router-link>

				<!--				<router-link-->
				<!--					tag="li"-->
				<!--					class="nav-item"-->
				<!--					active-class="active"-->
				<!--					exact-->
				<!--					:to="{ name: 'FlexFormInit' }"-->
				<!--				>-->
				<!--					<a class="nav-link">FlexFormsInit</a>-->
				<!--				</router-link>-->

				<router-link
					tag="li"
					:class="{ hide: loggedIn }"
					class="nav-item"
					active-class="active"
					exact
					:to="{ name: 'logIn' }"
				>
					<a class="nav-link">Login</a>
				</router-link>

				<router-link
					v-if="userCanSeeUsers"
					tag="li"
					:class="{ hide: !loggedIn }"
					class="nav-item"
					active-class="active"
					exact
					:to="{ name: 'userList' }"
				>
					<a class="nav-link">Users</a>
				</router-link>

				<router-link
					v-if="selfUser && selfUser.rtnow_admin"
					tag="li"
					:class="{ hide: !loggedIn }"
					class="nav-item"
					active-class="active"
					exact
					:to="{ name: 'facilities' }"
				>
					<a class="nav-link">Facilities</a>
				</router-link>

				<router-link
					v-if="userCanSeeAlert"
					tag="li"
					:class="{ hide: !loggedIn }"
					class="nav-item"
					active-class="active"
					exact
					:to="{ name: 'alertsList' }"
				>
					<a class="nav-link">Alerts</a>
				</router-link>

				<router-link
					v-if="userCanSeeSchedule"
					tag="li"
					:class="{ hide: !loggedIn }"
					class="nav-item"
					active-class="active"
					exact
					:to="{ name: 'schedule' }"
				>
					<a class="nav-link">Schedule</a>
				</router-link>

				<router-link
					v-if="userCanSeeCorelink"
					tag="li"
					:class="{ hide: !loggedIn }"
					class="nav-item"
					active-class="active"
					exact
					:to="{ name: 'conferenceList' }"
				>
					<a class="nav-link">CoreLink</a>
				</router-link>

				<router-link
					tag="li"
					:class="{ hide: !loggedIn }"
					class="nav-item"
					active-class="active"
					exact
					:to="{ path: '/phone/logs' }"
				>
					<a class="nav-link d-flex align-items-center"
						>Phone Logs<span
							v-if="vm_unresponded_count"
							class="badge badge-warning badge-pill"
							style="font-size: 12px; padding: 0; min-width: 1.5rem;"
							>{{ vm_unresponded_count }}</span
						></a
					>
				</router-link>

				<!--				<router-link-->
				<!--					v-if="selfUser && (selfUser.rtnow_rt || userCanSee.assessment)"-->
				<!--					tag="li"-->
				<!--					:class="{ hide: !loggedIn }"-->
				<!--					class="nav-item nav-action"-->
				<!--					style-->
				<!--					active-class="active"-->
				<!--					exact-->
				<!--					:to="'/assessment/new/init'"-->
				<!--				>-->
				<!--					<a class="nav-link">Assessment</a>-->
				<!--				</router-link>-->

				<router-link
					v-if="selfUser && loggedIn"
					tag="li"
					class="nav-item nav-action"
					active-class="active"
					exact
					:to="{ name: 'FlexFormListForms' }"
				>
					<a class="nav-link">FlexForms</a>
				</router-link>
			</ul>

			<template v-if="loggedIn">
				<div>
					<a :href="documentationLink" target="_blank" rel="noopener noreferrer" class="nav-link docs-link" style="color: rgba(255,255,255,.5); margin-right: 20px">
						<font-awesome-icon style="font-size: x-large" icon="fa-solid fa-book" />
					</a>
				</div>
			</template>



			<template v-if="selfUser">
				<span
					v-on-clickaway="hideUserDropDown"
					:class="{ hide: !loggedIn, show: showUserDropDown }"
					class="navbar-text dropdown"
				>
					<a
						class="nav-link avatar dropdown-toggle"
						style="position: relative"
						href
						v-on:click.prevent="showUserDropDown = !showUserDropDown"
					>
						<span
							:class="'fc-' + selfUser.color"
							style="width: 34px; height: 34px; border-radius: 50%; position: absolute; top: 50%; left: 0; margin-top: -17px;"
						>
							<img
								width="30"
								height="30"
								style="border-radius: 50%; position: relative; top: 2px; left: 2px;"
								src="../assets/avatar2.png"
							/>
						</span>
						<span style="padding-left: 25px; white-space: nowrap"
							>{{ selfUser.first_name }} {{ selfUser.last_name }}</span
						>
					</a>
					<div
						class="dropdown-menu blue-grey-text"
						:class="{ show: showUserDropDown }"
					>
						<router-link
							class="dropdown-item blue-grey-text"
							active-class="none"
							exact
							:to="{ name: 'userFormEdit', params: { id: selfUser.id } }"
							>Account</router-link
						>
						<div class="dropdown-divider"></div>
						<router-link
							class="dropdown-item blue-grey-text"
							active-class="none"
							exact
							:to="{ name: 'logOut' }"
							>Logout</router-link
						>
					</div>
				</span>
			</template>

			<form class="form-inline my-2 my-lg-0">
				<slot></slot>
			</form>
		</div>
	</nav>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

import { directive as onClickaway } from "vue-clickaway";

export default {
	name: "Nav.vue",

	directives: {
		onClickaway: onClickaway
	},

	data() {
		return {
			showUserDropDown: false,
			collapsed: true,
			baseURI: 'https://docs.',
			currentDomain: window.location.hostname,
			//user: {}
		};
	},

	computed: {
		...mapState(["vm_unresponded_count"]),
		...mapGetters(["loggedIn", "selfUser", "userCanSee", "logoTrans"]),

		iAmAdmin() {
			return (
				this.selfUser &&
				(this.selfUser.rtnow_admin ||
					(this.selfUser.admin_clients && this.selfUser.admin_clients.length) ||
					(this.selfUser.admin_facilities &&
						this.selfUser.admin_facilities.length))
			);
		},

		userCanSeeUsers() {
			if (this.selfUser && this.selfUser.facilities) {
				if (this.selfUser.rtnow_admin) {
					return true;
				}
				return !!this.selfUser.facilities.find(f => {
					return f.admin === true;
				});
			}
			return false;
		},

		userCanSeeSchedule() {
			if (this.selfUser && this.selfUser.facilities) {
				if (this.selfUser.rtnow_admin || this.selfUser.rtnow_rt) {
					return true;
				}
				return !!this.selfUser.facilities.find(f => {
					return f.rtnow_feature === true;
				});
			}
			return false;
		},

		userCanSeeCorelink() {
			if (this.selfUser) {
				if (this.selfUser.rtnow_admin || this.selfUser.rtnow_rt) {
					return true;
				} else if (this.selfUser.facilities) {
					return !!this.selfUser.facilities.find(f => {
						return f.corelink_feature === true;
					});
				}
			}
			return false;
		},

		userCanSeeAlert() {
			if (this.selfUser && this.selfUser.facilities) {
				if (this.selfUser.rtnow_admin) {
					return true;
				}
				return !!this.selfUser.facilities.find(f => {
					return f.rtnow_feature === true && f.admin === true;
				});
			}
			return false;
		},

		documentationLink() {
			return this.baseURI + this.currentDomain;
		},

		// moved to store/index.js
		// userCanSeeAssessment() {
		// 	if (this.selfUser && this.selfUser.facilities) {
		// 		return !!this.selfUser.facilities.find(f => {
		// 			return f.rtnow_feature === true && f.rt === true;
		// 		});
		// 	}
		// 	return false;
		// }
	},

	loggedIn() {
		if (this.loggedIn) {
			this.getSelfUser();
		}
	},

	methods: {
		...mapActions(["ajax", "getSelfUser"]),

		hideUserDropDown() {
			//alert('hideUserDropDown');
			this.showUserDropDown = false;

			//return false;
		}
	},

	watch: {
		// eslint-disable-next-line no-unused-vars
		$route(to, from) {
			// close the mobiel nav
			//alert('watch route');

			this.collapsed = true;
			this.showUserDropDown = false;
		}
	},

	mounted() {
		//if(this.loggedIn){
		this.getSelfUser();
		//}
	}
};
</script>

<style>
.nav-item.hide {
	display: none;
}

.navbar-dark .navbar-toggler {
	border-color: transparent;
}

.docs-link:hover{
	color: #FFFFFF !important;
}
</style>
